/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

export const adsServicePath =
  process.env.REACT_APP_API_URL || "https://adminapi.loosid.cc";
export const dashboardServicePath =
  process.env.REACT_APP_DASHBOARD_API_URL || "https://dash-api01.loosidapp.net";
export const audioServicePath =
  process.env.REACT_APP_AUDIO_SERVICE_API_URL ||
  "https://audio01.loosidapp.net";
export const centersServicePath =
  process.env.REACT_APP_CENTERS_API_URL || "https://centers01.loosidapp.net";
export const userServicePath =
  process.env.REACT_APP_USER_SERVICE_API_URL || "https://lus01.loosidapp.net";
export const backendServicePath = process.env.REACT_APP_BACKEND_API_URL;
export const loosidCorePath =
  process.env.REACT_APP_LOOSID_CORE_API_URL ||
  "https://loosid-core-dev-8fkub.ondigitalocean.app";

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color";
export const isMultiColorActive = true;
export const isDarkSwitchActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = true;
